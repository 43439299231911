<template>
    <div class="text-xs">
        <div class="no-print">
            <div class="row justify-content-center">
              <div class="col-md-6">
                  <button type="button" @click="$router.push('/refresh')" class="btn btn-sm btn-dark  my-3">Back to Dashboard</button>
                    <div class="sm-form ">
                        <label for="number">Lebar laporan (1-12)</label>
                        <select class='form-control' v-model="number">
                            <option v-for="(item, index) in 12" :key="index+'nmber'">{{item}}</option>
                            <option>center</option>
                        </select>
                    </div>
                    <label for="number">Posisi Kertas : </label>
                    <select class='form-control' v-model="position">
                        <option>left</option>
                        <option>center</option>
                    </select>
                    <hr>
                    <button type="button" @click="id_nota=0" class="btn btn-sm btn-dark float-right "><span class="typcn typcn-arrow-repeat"></span></button>
                    <button type="button" @click="prints" class="btn btn-sm btn-dark  ">Print <span class="typcn typcn-print"></span></button>
                    <hr>
              </div>
            </div>
        </div>
        <div class="row " :class="position=='center'?'justify-content-center':''">
          <div :class="`col-md-${number} shadow`">
        <p class="text-center text-lg font-bold">{{datatoko.nama_toko}}</p>
        <p class="text-center text-sm font-semibold">{{datatoko.alamat}}</p>
        <br>
        <div class="row justify-content-between">
          <div class="col-6">
            <p>{{datanya[0].nota}}</p>
          </div>
          <div class="col-6 text-right">
              {{format(datanya[0].tanggal)}}
          </div>
          <div class="col-12">
              <hr>
          </div>
          <div class="col-12">
              <table class="table table-sm table-responsive" style="width:100%;" border='1'>
                  <tr style="width:100%;">
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;"></td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;"></td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">Admin</td>
                  </tr>
                  <tr style="width:100%;" v-for="(item, index) in transaksi" :key="index+'transaksi'">
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{item.nama_produk}}</td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{item.jumlah}} x {{item.harga}} x /{{item.satuan}}</td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{item.total}} (diskon {{item.diskon}}%)</td>
                  </tr>
                 
                  <tr style="width:100%;" class="border-none">
                      <td class="border-none"></td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">Harga Total</td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{datanya[0].total}}</td>
                  </tr>
                  <tr style="width:100%;">
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;"></td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">Uang Dibayar</td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{datanya[0].uang}}</td>
                  </tr>
                  <tr style="width:100%;">
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;"></td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">Kembalian</td>
                      <td style="border-top:1px solid gray;border-bottom:1px solid gray;padding:5px;">{{parseInt(datanya[0].uang)-parseInt(datanya[0].total)}}</td>
                  </tr>
              </table>
          </div>
        </div>
    </div>    
    </div>
    </div>
</template>
<script>
import axios from 'axios'
import autophp from '@/plugins/autophp_kasir'
let sdb = new autophp();
export default{
    data(){
        return{
            number:6,
            total:0,
            uang:0,
            transaksi:[],
            position:'center',
            datatoko:[],
            datanya:[],
            search:'',
            notas:[],
            id_nota:0
        }
    },
     computed:{
      td(){
          let data=this.notas;
            data=data.filter(e=>{
                if(e.nota.toLowerCase().indexOf(this.search)!=-1){
                    return e
                }
            })
            return data
        }
    },
    methods: {
        format(tanggal) {
            console.log(tanggal)
              return this.$datefns.format(
                new Date(tanggal),
                "DD MMMM YYYY/HH-mm"
              );
            },
        prints(){
            window.print();
        },
        ambilnota(item){
            this.$route.query.id=item.id;
            this.getData();
        },
         getNota(){
          sdb.collection("app_kasir_transaksi_nota").doc().get("*","order by id").then(res => {
            this.notas=res;
              this.$forceUpdate();
          });
      },
      getToko(){
           sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
            this.datatoko=res[0]
            this.$forceUpdate();
          });
      },
        getData(){
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT tb3.nama_produk,tb2.harga,tb4.satuan,tb2.total,tb2.jumlah,tb2.diskon FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota left join app_kasir_produk tb3 on tb3.id=tb2.id_produk left join app_kasir_produk_satuan tb4 on tb4.id=tb2.satuan where tb1.nota='${this.$route.query.nota}' order by tb1.id`).then(res => {
                this.transaksi=res;
            });
            sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where nota='${this.$route.query.nota}' order by id`).then(res => {
                this.datanya=res;
                // setTimeout(() => {
                //     window.print()
                // }, 1000);
            });
            
        }
    },
    mounted() {
        this.getData();
        this.getToko();
    },
}
</script>
<style scoped>
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  .table-print {
    border: 1px solid black;
  }

  .d-footer-printer-ttd{
    display:block;
    position:absolute;
    bottom:150px;
    right:100px;
  }
}
</style>